/** @format */

import "./Home.scss";

import Navbar from "../../components/navbar/Navbar";
import HeroTitle from "../../components/heroTitle/HeroTitle";
import About from "../../components/about/About";
import Portfolio from "../../components/portfolio/Portfolio";
import Footer from "../footer/Footer";

import ReactGA from "react-ga";
const TRACKING_ID = "G-KBKWPQJWQL"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function Home() {
  return (
    <section className="home-container">
      <Navbar />
      <HeroTitle />
      <About />
      <Portfolio />
      {/* <Skills /> */}
      <Footer />
    </section>
  );
}

export default Home;
