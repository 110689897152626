/** @format */

export const Projects = [
  {
    title: "OpenFashion",
    cardName: "E-Commerce Website",
    summary:
      "A full-stack e-commerce website which allows users to browse, review and purchase products.",
    stack: ["React.js", "Node.js", "Stripe", "EmailJS"],
    github: "https://github.com/Wahaj-Arsal/OpenFashion",
    url: "https://openfashion.onrender.com",
    image: "openfashion_v2.jpg",
  },
  {
    title: "PlayFlix",
    cardName: "Streaming Website",
    summary:
      "A full-stack streaming web application which allows users to upload videos, create and delete comments",
    stack: ["React.js", "Node.js", "Express"],
    github: "https://github.com/Wahaj-Arsal/Play_Flix",
    url: "https://playflix.onrender.com",
    image: "playflix.jpg",
  },
  {
    title: "Puddle of Chords",
    cardName: "Band Site",
    summary:
      "A full-stack streaming web application which allows users to upload videos, create and delete comments",
    stack: ["React.js", "Node.js", "Express"],
    github: "https://github.com/Wahaj-Arsal/Chords",
    url: "https://puddleofchords.netlify.app",
    image: "chords.jpg",
  },
];
